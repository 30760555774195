import { useRoutes } from 'react-router-dom';
import router from 'src/router';

import { CssBaseline } from '@mui/material';
import ThemeProvider from './theme/ThemeProvider';
import { Toaster } from 'react-hot-toast';

function App() {
  const content = useRoutes(router);

  const toasterOptions = {
    style: {
      fontWeight: 500,
      fontFamily: "'Montserrat', sans-serif"
    }
  };

  return (
    <ThemeProvider>
      <CssBaseline />
      <Toaster toastOptions={toasterOptions} />
      {content}
    </ThemeProvider>
  );
}
export default App;

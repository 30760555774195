import DesignServicesTwoToneIcon from '@mui/icons-material/DesignServicesTwoTone';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import SurfingOutlinedIcon from '@mui/icons-material/SurfingOutlined';
import RecordVoiceOverOutlinedIcon from '@mui/icons-material/RecordVoiceOverOutlined';
import TravelExploreOutlinedIcon from '@mui/icons-material/TravelExploreOutlined';
import StarOutlineOutlinedIcon from '@mui/icons-material/StarOutlineOutlined';
import PeopleOutlineOutlinedIcon from '@mui/icons-material/PeopleOutlineOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import VerifiedUserOutlinedIcon from '@mui/icons-material/VerifiedUserOutlined';
import PaidIcon from '@mui/icons-material/Paid';

export const MenuIcons = {
  Dashboard: DashboardOutlinedIcon,
  Guides: RecordVoiceOverOutlinedIcon,
  Tours: TravelExploreOutlinedIcon,
  Moderation: VerifiedUserOutlinedIcon,
  ReviewsAndRatings: StarOutlineOutlinedIcon,
  Users: PeopleOutlineOutlinedIcon,
  Settings: SettingsOutlinedIcon,
  Tourists: SurfingOutlinedIcon,
  Transaction: PaidIcon
};

import { Suspense, lazy } from 'react';
import SuspenseLoader from './components/SuspenseLoader';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// Authentication
const Login = Loader(
  lazy(() => import('src/pages/Authentication/Login/Login'))
);

// Dashboards
const Dashboard = Loader(lazy(() => import('src/pages/Dashboard')));

// Guides
const Guides = Loader(lazy(() => import('src/pages/Guides')));
const GuideDetails = Loader(
  lazy(() => import('src/pages/Guides/GuideDetails'))
);

// Tours
const Tours = Loader(lazy(() => import('src/pages/Tours')));
const TourDetails = Loader(lazy(() => import('src/pages/Tours/TourDetails')));

//Tourists
const Tourists = Loader(lazy(() => import('src/pages/Tourists')));

//Moderator
const Moderator = Loader(lazy(() => import('src/pages/Moderator')));

// Error Pages
const Status404 = Loader(lazy(() => import('src/pages/Error/Status404')));

//Transactions
const Transactions = Loader(lazy(() => import('src/pages/Transaction/index')));

export const RouterLazyLoader = {
  Login,
  Dashboard,
  Status404,
  Guides,
  GuideDetails,
  Tours,
  TourDetails,
  Tourists,
  Moderator,
  Transactions
};

import { MenuIcons } from './MenuIcons';
import { UserRoles } from '../../../../constants/user-roles';

export interface IMenuItem {
  link: string;
  icon: any;
  title: string;
  isVisible?: (user: any) => boolean;
}

const MenuItems: IMenuItem[] = [
  {
    link: '/app/dashboard',
    title: 'Dashboard',
    icon: MenuIcons.Dashboard
  },
  {
    link: '/app/guides',
    title: 'Guides',
    icon: MenuIcons.Guides
  },
  {
    link: '/app/tours',
    title: 'Tours',
    icon: MenuIcons.Tours
  },
  {
    link: '/app/tourists',
    title: 'Tourists',
    icon: MenuIcons.Tourists
  },
  {
    link: '/app/reviews-ratings',
    title: 'Reviews & Ratings',
    icon: MenuIcons.ReviewsAndRatings,
    isVisible: (user: any) => user.role === UserRoles.Admin
  },
  {
    link: '/app/users',
    title: 'Users',
    icon: MenuIcons.Users,
    isVisible: (user: any) => user.role === UserRoles.Admin
  },
  {
    link: '/app/transaction',
    title: 'Transaction',
    icon: MenuIcons.Transaction
  },
  {
    link: '/app/settings',
    title: 'Settings',
    icon: MenuIcons.Settings,
    isVisible: (user: any) => user.role === UserRoles.Admin
  }
];

export default MenuItems;
